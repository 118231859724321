import React, { useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import './ContactForm.css'

var APIKey = process.env.REACT_APP_APIKey

function ContactForm() {

    const [verify, setVerify] = useState(false);
    const [sent, setSent] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('question');
    const [message, setMessage] = useState('');

    const recaptchaRef = React.createRef();

    async function isHuman(value) {
        setSent(true)
        const data = await axios({
            method: 'post',
            url: 'https://api.isitphish.com/v1/email',
            data: {"captcha": value, "name": name, "email": email, "subject": subject, "message": message},
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': APIKey
            }
        })
        const result = data.data.body.success;
        setVerify(result)
        };

    useEffect(() => {
        if(verify === true){
            setSent(true)
        }
      }, [verify])

    const handleSubmit = e => {
        e.preventDefault()
        recaptchaRef.current.execute();
        };
    
    const onChange = value => {
        isHuman(value)
        };

    return (
        <div className="contact-container">
                <div className="text-item-header">
                    <h1>Contact Us</h1>
                </div>
                <div className="text-item">
                    <p>Whether you're ready to join us or have some questions before you sign up, fill in the below form and we'll get back to you ASAP.</p>
                </div>
            <div className="contact-wrapper">
                <div className="contact-item">
                    <form onSubmit={handleSubmit}>
                        <div className="form-item">
                            <label>Name*:</label>
                            <input className="contact-inupt" type="text" name="name" onChange={e => setName(e.target.value)} required />
                        </div>
                        <div className="form-item">
                            <label>Email*:</label>
                            <input className="contact-inupt" type="email" name="email" onChange={e => setEmail(e.target.value)} required/>
                        </div>
                        <div className="select-item">
                            <label>Subject:</label>
                            <select type="text" name="subject" onChange={e => setSubject(e.target.value)} required>
                                <option value="question">Question</option>
                                <option value="free">Subscription: Free</option>
                                <option value="basic">Subscription: Basic</option>
                                <option value="business">Subscription: Business</option>
                                <option value="enterprise">Subscription: Enterprise</option>
                                <option value="enterprise">Issue</option>
                            </select>
                        </div>
                        <div className="form-item">
                            <label>Message*:</label>
                            <textarea name="message" onChange={e => setMessage(e.target.value)} required/>
                        </div>
                        <div className="form-submit">
                            <ReCAPTCHA ref={recaptchaRef} size="invisible" theme='dark' sitekey="6LfxRC0aAAAAALv8oYD0TfoDi24YDIBm7G60wDXU" onChange={onChange}/>
                            { sent ? (
                                        <input className="contact-submit-button-sent" value="Sent"/>
                                    ) : (
                                        <input className="contact-submit-button" type="submit" value="Submit"/>
                                    )
                            }
                        </div>
                    </form>
                </div>
            </div>
                <div className="text-item-email">
                    <p>Prefer email? No problem, contact us at <a href="mailto:contact@isitphish.com">contact@isitphish.com</a></p>
                </div>
        </div>
    )
} 

export default ContactForm
