import React from 'react'
import '../../App.css';
import TermsContent from '../TermsContent';
import '../TermsContent.css';

function Terms () {
    return (
        <div>
            <TermsContent/>
        </div>
    )
}

export default Terms;