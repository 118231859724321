import React, { useState } from 'react';
import { CgCloseR } from 'react-icons/cg';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { RiShieldCrossLine } from 'react-icons/ri';
import '../App.css';
import './Header.css';
import image from '../images/img-home.jpg';
import axios from 'axios'
import loader from '../images/Spinner-1s-200px.gif';

var APIKey = process.env.REACT_APP_APIKey

function Header() {
    const [query, setQuery] = useState('');
    const [phishing, setPhishing] = useState([]);
    const [legitimate, setLegitimate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hide, setHide] = useState(false);
    const [show, setShow] = useState(false)

    async function fetchData() {
        const { data } = await axios({
            method: 'post',
            url: 'https://api.isitphish.com/v1/query',
            data: {"url": query},
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': APIKey
            }
        });

        const phish = data.body.phish;
        const legit = data.body.legit;
        setLoading(true)
        setShow(true)
        setPhishing(phish);
        setLegitimate(legit);
    };

    const handleSubmit = e => {
        setHide(false)
        setLoading(false)
        e.preventDefault()
        fetchData()
        }
        
    return (
        <div className='header-container' style={{image}}>
            <div className='header-wrapper'>
                <div className='header-item'>
                    <h1>isitphish </h1>
                </div>
                <div className='header-item'>
                    <p>The real-time phishing detection API</p>
                </div>
                <form className="header-form" onSubmit={handleSubmit}>
                    <input className="header-form-url" type="url" placeholder="https://www.example.com" pattern="https?://.+" value={query} onChange={e => setQuery(e.target.value)} required/>
                    <input className="header-form-submit" type="submit" value="Submit"/>
                </form>
                <div style={hide ? {display: 'none'}:{width:'100%',display:'flex',flexDirect:'column',alignItems:'center',textAlign:'center',justifyContent:'center'}}>
                    { loading ? (
                        <div style={show ? {}:{display: 'none'}} className="result-wrapper">
                            <div>
                                {phishing > legitimate ? (
                                <div className='result-phish'>
                                    <CgCloseR onClick={() => setHide(true)} className='delete-icon'/>
                                    <h1><RiShieldCrossLine className='safe'/> We think this is phishing!</h1>
                                    {Math.round(phishing*1000) / 10}% Phishing
                                    <br/>
                                    {Math.round(legitimate*1000) / 10}% Legitimate
                                    <br/>
                                    <div className='terms-result-wrapper'>
                                        <a className="terms-link" href="/terms">Terms of Service</a>
                                    </div>
                                </div>
                                ) : (
                                <div className='result-legit'>
                                    <CgCloseR onClick={() => setHide(true)} className='delete-icon'/>
                                    <h1><AiFillSafetyCertificate className='safe'/> We think this is safe!</h1>
                                    {Math.round(legitimate*1000) / 10}% Legitimate
                                    <br/>
                                    {Math.round(phishing*1000) / 10}% Phishing
                                    <br/>
                                    <div className='terms-result-wrapper'>
                                        <a className="terms-link" href="/terms">Terms of Service</a>
                                    </div>
                                </div>
                                )
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="result-wrapper">
                            <img src={loader} width="60" height="60" alt='loading'/>
                        </div>
                    )
                    }
                        
                    </div>
                </div>
            </div>
    )
}

export default Header
