import React from 'react';
import '../App.css';
import './HomeContent.css'
import Highlight from 'react-highlight';

function HomeContent() {
    return (
        <div className='home-content-container'>
            <div className='home-content-wrapper'>
                <div className='home-content-item'>
                    <h1>How it works</h1>
                </div>
                <div className='home-content-item'>
                    <p><strong>isitphish</strong> utilises machine learning to detect phishing URLs in real-time. Evaluating 140 million URL syntax features, <strong>isitphish</strong> is able to detect zero-day phishing attacks without the use of blocklists, with an accuracy of 97%.</p> 
                </div>
                <br/>
                <div className='home-content-item-legit'>
                    <h2><span>https://en.wikipedia.org/wiki/Phishing</span></h2> 
                </div>
                <br/>
                <div className='home-content-item-phish'>
                    <h2><span>http://</span>website.com/1<span>https</span>/<span>34.237.113.11</span></h2> 
                </div>
                <br/>
                <div className='home-content-item'>
                    <p> <strong>isitphish</strong> enables developers to detect phishing attacks and protect their users from suspicious emails, fradulent messages, dangerous links, or just about any phishing threat! </p> 
                </div>
            </div>
            <div className='code-container'>
        <div className='code'>
          <div className='code-wrapper'>
            <div className='code-item'>
              <div className='code-item-header'>
                <h2>Request</h2>
                <Highlight language="html">
                {`
POST <API endpoint> HTTP/1.1
Content-Type: application/json
x-api-key: <your API key>

{"url": "https://www.google.com"}
                `}
                </Highlight>
              </div>
            </div>
            <div className='code-item'>
              <div className='code-item-header'>
                <h2>Response</h2>
                <Highlight language="html">
                {`
{
  "url": "https://www.google.com",
  "phishing": 0.0028701753637257192,
  "legitimate": 0.9971298246362743
}
                `}
                </Highlight>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='home-content-item'>
        <p>Why not try the new <a href="https://github.com/opsdisk/pyisitphish" target="_blank" rel="noreferrer">Python API Client</a> for isitphish!</p> 
        <br/>
      </div>
      <div className='home-content-item'>
        <p>If you're developing a product and are interested in keeping your users secure, take a look at <a href='/pricing'>our plans</a>.</p> 
        <br/>
      </div>
    </div>
    )
}

export default HomeContent