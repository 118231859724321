import React from 'react'
import '../../App.css';
import CyberCrow from '../../images/cybercrow.png';

function NotFoundPage () {
    return (
        <div className="container-404">
            <div className="image-404">
                <img src={CyberCrow} width="290" height="220" alt='Cyber Crow' />
            </div>
            <div className="text-404">
                <h1>404 Page not found</h1>
            </div>
        </div>
    )
}

export default NotFoundPage;