import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import Home from './components/pages/Home';
import Pricing from './components/pages/Pricing'
import About from './components/pages/About'
import Footer from './components/Footer';
import Contact from './components/pages/Contact';
import Terms from './components/pages/Terms';
import FAQ from './components/pages/FAQ';
import ScrollToTop from './components/ScrollToTop';
import NotFoundPage from './components/pages/NotFoundPage';
import CookieConsent, { getCookieConsentValue, Cookies } from "react-cookie-consent";
import { initGA } from "./ga-utils";
import Privacy from './components/pages/Privacy';

function App() {
  const handleAcceptCookie = () => {
      initGA('G-4G28KKHD1B');
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <>
    <Router>
      <ScrollToTop>
        < NavBar/>
        <Switch>
            <Route path='/' exact component={Home}/>
            <Route path='/pricing'  component={Pricing}/>
            <Route path='/about'  component={About}/>
            <Route path='/contact'  component={Contact}/>
            <Route path='/terms'  component={Terms}/>
            <Route path='/privacy'  component={Privacy}/>
            <Route path='/faq'  component={FAQ}/>
            <Route path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
        </Switch>
        <Footer/>
      </ScrollToTop>
    </Router>
    <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        This site uses cookies from Google to deliver its services and to analyze traffic. View our Privacy Policy <a href="/privacy">here</a> for more details.
      </CookieConsent>
    </>
  );
}

export default App;
