import React, { useState, useEffect } from 'react'
import './Stats.css'
import axios from 'axios'

var APIKey = process.env.REACT_APP_APIKey

function Stats() {
    const [total_count, setTotal_count] = useState('');
    const [phishing, setPhishing] = useState('');
    const [legitimate, setLegitimate] = useState('');
    const [execution_time, setExecution_time] = useState('');
    const [loaded, setLoaded] = useState(false);

    async function fetchData() {
        const { data } = await axios({
            method: 'get',
            url: 'https://api.isitphish.com/v1/stats',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': APIKey
            }
        });
        console.log(data)
        const phishing_response = data.phishing_count;
        const legitimate_response = data.legitimate_count;
        const count_response = data.total_count;
        const time_response = data.execution_time;
        setPhishing(phishing_response);
        setLegitimate(legitimate_response);
        setTotal_count(count_response);
        setExecution_time(time_response);
        setLoaded(true)
    };

    useEffect(() => {
        fetchData()
    },[]);

    return (
        <div className='stats-container'>
            { loaded ? (
                <div className='stats'>
                    <div className='stats-wrapper'>
                        <div className='stats-items'>
                            <h2>{total_count.toLocaleString(undefined, {maximumFractionDigits:2})}</h2>
                            queries
                        </div>
                        <div className='stats-items'>
                            <h2>{execution_time}ms</h2>
                            response time
                        </div>
                    </div>
                    <div className='stats-wrapper'>
                        <div className='stats-items'>
                            <h2>{phishing.toLocaleString(undefined, {maximumFractionDigits:2})}</h2>
                            phishing
                        </div>
                        <div className='stats-items'>
                            <h2>{legitimate.toLocaleString(undefined, {maximumFractionDigits:2})}</h2>
                            legitimate
                        </div>
                    </div>                        
                </div>
            ) : (
                <div className='stats'>
                <div className='stats-wrapper'>
                    <div className='stats-items'>
                        <h2>0</h2>
                        queries
                    </div>
                    <div className='stats-items'>
                        <h2>0ms</h2>
                        response time
                    </div>
                </div>
                <div className='stats-wrapper'>
                    <div className='stats-items'>
                        <h2>0</h2>
                        phishing
                    </div>
                    <div className='stats-items'>
                        <h2>0</h2>
                        legitimate
                    </div>
                </div>                        
            </div>
            )}
        </div>

    )
}

export default Stats
