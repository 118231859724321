import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';

const onMouseOver = event => {
    const el = event.target;
    let white = "#fa9a39"
    el.style.color = white
  }
  
  const onMouseOut = event => {
    const el = event.target;
    let grey = "#fff";
    el.style.color = grey;
  }

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
                <img src={Logo} width="50" height="50" alt='logo' />
            </Link>
            <Link to='/' className='social-title'>
                isitphish
            </Link>
          </div>
        </div>
        <div className='social-media-wrap'>
            <div className='social-icons'>
            <a
                className='social-icon-link twitter'
                href='https://www.twitter.com/isitphish'
                target='_blank'
                rel="noreferrer"
                aria-label='Twitter'
            >
                <i className='fab fa-twitter' 
                onMouseEnter={event => onMouseOver(event)}
                onMouseOut={event => onMouseOut(event)} />
            </a>
              <a
                  className='social-icon-link facebook'
                  href='https://fb.me/isitphish'
                  target='_blank'
                  rel="noreferrer"
                  aria-label='Facebook'
              >
                  <i className='fab fa-facebook' 
                  onMouseEnter={event => onMouseOver(event)}
                  onMouseOut={event => onMouseOut(event)} />
              </a>
              <Link
                  className='social-icon-link email'
                  to='/contact'
                  aria-label='Contact'
              >
                  <i className="fas fa-envelope-square"
                  onMouseEnter={event => onMouseOver(event)}
                  onMouseOut={event => onMouseOut(event)} />
              </Link>
            </div>
        </div>
        <div className='social-media-wrap'>
            <small className='website-rights'>isitphish © 2021</small>
        </div>
        <div className='terms'>
            <small><a className="terms-link" href="/terms">Terms of Service</a></small>
            <small><a className="terms-link" href="/privacy">Privacy Policy</a></small>
        </div>
      </section>
    </div>
  );
}

export default Footer;