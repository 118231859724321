import React from 'react'
import './AboutHeader.css'
import Oxford from '../images/oxford-logo.png'
import Derby from '../images/derby-logo.png'

function AboutHeader() {
    return (
        <div className='about-container'>
            <div className='about-wrapper'>
                <div className='about-item'>
                    <h1>The only real-time phishing detection tool</h1>
                </div>
                <div className='about-item'>
                    <p>The idea for <strong>isitphish</strong> was formed in 2013, looking to solve phishing by providing an automated phishing detection tool powered by AI. Based on research undertaken while at the University of Oxford and the University of Derby, <strong>isitphish</strong> provides the only real-time, accurate, and reliable phishing detection datasource.</p> 
                </div>
            <div className='logo-wrapper'>
                <div className='logo-items'>
                    <img src={Oxford} width='150' height='150' alt='University of Oxford' />
                    <img src={Derby} width='150' height='150' alt='University of Derby' />
                </div>
            </div>
            <div className='contributor-wrapper'>
                <div className='about-item'>
                    <h1>Contributors</h1>
                </div>
                <div className='about-item'>
                    <ul>
                        <li>Brennon - <a href="https://twitter.com/opsdisk?s=21" target="_blank" rel="noreferrer">@opsdisk</a> - <a href="https://github.com/opsdisk/pyisitphish" target="_blank" rel="noreferrer">Python API Client</a></li>
                    </ul>
                </div>
            </div>
            </div>
        </div>
    )
}

export default AboutHeader
