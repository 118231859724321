import React from 'react'
import './Questions.css'

function Questions() {
    return (
        <div className='faq-container'>
            <div className='faq-wrapper'>
                <div className='faq-item'>
                    <h1>Frequently Asked Questions</h1>
                    <br/>
                </div>
                <div className='faq-item'>
                    <h2>What is isitphish?</h2>
                    <p><strong>isitphish</strong> is a real-time phishing detection API that uses machine learning to identify phishing URLs from legitimate URLs. </p> 
                </div>
                <div className='faq-item'>
                    <h2>How does it work?</h2>
                    <p>We've analysed over 140 million syntax features of phishing and legitimate URLs to provide a model that can detect phishing URLs with an accuracy of 97%.</p> 
                </div>
                <div className='faq-item'>
                    <h2>Who needs isitphish?</h2>
                    <p>Our phishing detection API was designed with founders, makers, and developers in mind. If you're building a platform or app and want to keep your users secure from the outset, <strong>isitphish</strong> is for you!</p> 
                </div>
                <div className='faq-item'>
                    <h2>How do I use isitphish?</h2>
                    <p>Are you building an app or platform that has user generated content? How do you ensure phishing links aren't sent to your users? The <strong>isitphish</strong> API can be used to block phishing links, or just warn users there's something "phishy" about a link.</p> 
                </div>
                <div className='faq-item'>
                    <h2>Do you have an API client?</h2>
                    <p>Thanks to <a href="https://twitter.com/opsdisk?s=21" target="_blank" rel="noreferrer">Brennon @opsdisk</a> the simple answer is yes. You can use the following <a href="https://github.com/opsdisk/pyisitphish" target="_blank" rel="noreferrer">Python API Client</a> to integrate <strong>isitphish</strong> into your Python codebase.</p> 
                </div>
                <div className='faq-item'>
                    <h2>Does it get it wrong?</h2>
                    <p>Who doesn't get things wrong sometimes!? <strong>isitphish</strong> has an accuracy of over 97%, which means sometimes you may receive an incorrect detection. We work on improving these detection rates every day, the most up-to-date rates can be seen on <a href="/pricing">our plans</a> page.</p> 
                </div>
                <div className='faq-item'>
                    <h2>I've found a mistake!</h2>
                    <p>You go, Glen Coco! Contact us <a href="/contact">here</a> or send an email to <a href="mailto:phishing@isitphish.com">phishing@isitphish.com</a> and we'll take a look.</p> 
                </div>
            </div>
        </div>
    )
}

export default Questions
