import React from 'react';
import './Cards.css';
import { TiTick } from 'react-icons/ti'
import { Button } from './Button';

function Cards({children}) {
  return (
    <div className='cards'>
      <div className='api-item'>
          <h1>Real-time phishing detection API</h1>
      </div>
      <div className='api-item'>
          <h4>The only real-time phishing detection API for researchers and companies alike. Whether you're tracking phishing campaigns on a budget, or protecting your platform's users.</h4>
      </div>
      <div className='api-item'>
          <h5>Select the plan that suits your needs and budget.</h5>
      </div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <li className='cards__item'>
              <div className='cards__item__link'>
                <div className='cards__item__logo'>
                  <i className='fas fa-gift fa-4x'></i>
                </div>
                <figure className='cards__item__pic-wrap' data-category='Free'/>
              <div className='cards__item__info'>
                <h4 className='cards__item__header'>£0</h4>
                <h4 className='cards__item__header_detail'>per month</h4> 
                <div className='cards__item__text'>
                      <TiTick className='tick'/> 1,000 queries
                      <br/>
                      <br/>
                      <Button link='/contact' buttonStyle='btn--primary'>Contact Us</Button>
                </div>
              </div>
              </div>
            </li>
            <li className='cards__item'>
              <div className='cards__item__link'>
                <div className='cards__item__logo'>
                  <i className='fas fa-user fa-4x'></i>
                </div>
                <figure className='cards__item__pic-wrap' data-category='Basic'/>
              <div className='cards__item__info'>
                <h4 className='cards__item__header'>£20</h4> 
                <h4 className='cards__item__header_detail'>per month</h4> 
                <div className='cards__item__text'>
                      <TiTick className='tick'/> 100,000 queries
                      <br/>
                      <br/>
                      <Button link='https://app.chimpcharge.com/pay/oJWw5LkzqYOinuplFYzW' buttonStyle='btn--primary'>Sign Up</Button>
                </div>
              </div>
              </div>
            </li>
            <li className='cards__item'>
              <div className='cards__item__link'>
                <div className='cards__item__logo'>
                  <i className='fas fa-users fa-4x'></i>
                </div>
                <figure className='cards__item__pic-wrap' data-category='Business'/>
              <div className='cards__item__info'>
                <h4 className='cards__item__header'>£100</h4>
                <h4 className='cards__item__header_detail'>per month</h4> 
                <div className='cards__item__text'>
                      <TiTick className='tick'/> 1,000,000 queries
                      <br/>
                      <br/>
                      <Button link='https://app.chimpcharge.com/pay/TNMLwsmnBCOSS1JOo6Lr' buttonStyle='btn--primary'>Sign Up</Button>
                </div>
              </div>
              </div>
            </li>
            <li className='cards__item'>
              <div className='cards__item__link'>
                <div className='cards__item__logo'>
                  <i className='fas fa-building fa-4x'></i>
                </div>
                <figure className='cards__item__pic-wrap' data-category='Enterprise'/>
              <div className='cards__item__info'>
                <h4 className='cards__item__header'>Contact</h4>
                <h4 className='cards__item__header_detail'>Custom Pricing</h4> 
                <div className='cards__item__text'>
                      <TiTick className='tick'/> Custom queries
                      <br/>
                      <br/>
                      <Button link='/contact' buttonStyle='btn--primary'>Contact Us</Button>
                </div>
              </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className='api-item'>
          <h1>All plan features</h1>
      </div>
      <div className='api-item'>
            <i className="fas fa-fighter-jet fa-3x"></i>
          <h3>Speed</h3>
          <p>All our plans receive the fastest response rates possible, with most queries taking less than 200ms.</p>
      </div>
      <div className='api-item'>
        <i className="fas fa-history fa-3x"></i>
        <h3>Availability</h3>
        <p>We build on AWS infrastructure so you can rely on unbeatable uptime and reliability.</p>
      </div>
      <div className='api-item'>
        <i className="fas fa-globe-europe fa-3x"></i>
        <h3>Scale</h3>
        <p>All plans can make 1,000 concurrent queries - <a href="/contact">contact</a> us if you need more.</p>
      </div>
      <div className='api-item'>
            <i className="fas fa-clipboard-check fa-3x"></i>
          <h3>Accuracy</h3>
          <p>isitphish provides an accuracy of 97%, with a false positive rate of 4% and false negative rate of 2%. We're improving these figures all the time.</p>
      </div>
      <div className='api-item'>
        <i className="fas fa-envelope-open-text fa-3x"></i>
        <h3>Support</h3>
        <p>No matter how much you pay you'll get our best in class support (even the free plan!).</p>
      </div>
      <div className='api-item'>
        <i className="fas fa-crow fa-3x"></i>
        <h3>Something missing?</h3>
        <p>We know fixed plans can be restrictive. If you can't find the right plan for you, <a href="/contact">contact</a> us now for a custom plan. You dont need to be an enterprise to get enterprise level customisation!</p>
      </div>
    </div>
  );
}

export default Cards;