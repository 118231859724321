import React from 'react'
import '../../App.css';
import Header from '../Header';
import Stats from '../Stats';
import HomeContent from '../HomeContent'

function Home () {
    return (
        <>
            <Header />
            <Stats />
            <HomeContent />
        </>
    )
}

export default Home;