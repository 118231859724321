import React from 'react'
import '../../App.css';
import Cards from '../Cards';

function Home () {
    return (
        <>
            <Cards />
        </>
    )
}
export default Home;