import React from 'react'
import '../../App.css';
import ContactForm from '../ContactForm';

function Contact () {
    return (
        <div>
            <ContactForm/>
        </div>
    )
}

export default Contact;