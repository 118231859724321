import React from 'react'
import '../../App.css';
import Questions from '../Questions';

function FAQ () {
    return (
        <div>
            <Questions/>
        </div>
    )
}

export default FAQ;