import React from 'react'
import '../../App.css';
import AboutHeader from '../AboutHeader';

function About () {
    return (
        <div>
            <AboutHeader/>
        </div>
    )
}

export default About;