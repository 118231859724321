import React from 'react'
import '../../App.css';
import PrivacyPolicy from '../PrivacyPolicy';
import '../TermsContent.css';

function Privacy () {
    return (
        <div>
            <PrivacyPolicy/>
        </div>
    )
}

export default Privacy;